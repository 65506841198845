@import url("https://fonts.googleapis.com/css2?family=M+PLUS+1+Code&family=Source+Code+Pro&display=swap");

@import url("https://fonts.googleapis.com/css2?family=M+PLUS+1+Code&family=Source+Code+Pro&display=swap");

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@200;300&display=swap');

/* Universal */

* {
  box-sizing: border-box;
  margin: 0;
}

body {
  background-color: #252526;
}

p {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: 1.3rem;
  text-align: justify; 
}

h1 {
  text-align: left;
  font-family: "M Plus 1 Code", sans-serif;

}

button {
  cursor: pointer;
}



/* Header */

.header-and-nav {
  background-color: #1e1e1e;
}

header {
  font-family: "M Plus 1 Code", sans-serif; 
  color: #27c3df;
  padding: 1rem;
  font-size: 2rem;
  display: flex;
}

header img {
  margin: 0 2rem;
  border-radius: 50%;
  width: 10rem;
  align-self: center;
}
.headers {
  display: inline-block;
  justify-content: space-around;
  margin: 1rem;
  margin-top: 3rem;
}
header h2 {
  font-weight: 200;
}
.headers a {
  color: white;
}
/* Navigation */

nav {
  width: 100vw;
}

nav ul {
  width: 30%;
  display: flex;
  justify-content: space-between;
  list-style: none;
  padding: 1rem;
}

nav li.selected a,
nav a.selected {
  color: #27c3df;
  text-shadow: 0 2.5px 5px rgba(39, 195, 223, 0.8); 

}
nav li {
  font-family: "Roboto", sans-serif;  
  font-weight: 100;
  padding: 0.5rem;
  font-weight: 200;
  font-size: 2rem;
}
nav li:hover {
  color: #27c3df;
}

nav a{
  color:#B267E6;;
  text-decoration: none;
}

nav a:hover{
  color: #27c3df;
}

.hamburger {
  display: none;
}

.links {
  position: absolute;
  top: 1rem;
  right: 3rem;
  display: flex;
  flex-direction: row;
}
.links i {
  font-size: 2rem;
  margin: 0.4rem;
  color: #b267e6;
}
.links i:hover {
  color: #27c3df;
}

/* Skills and Courses */
.skills-and-coursework {
  font-family: "Source Code Pro", monospace;

}

.skills-segment {
  text-align: center;
  margin: .5rem;
  display: grid;
  grid-template-columns: repeat(6, 1fr); 
      
}

.skills-and-coursework h1 {
  color: #cd9731;
}

.skill {
  color: #ec5598;
  font-size: 2rem;
  z-index: -1;
  margin: 2rem;
}

.spin-animation {
  animation: spin 2s linear infinite;
}


@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.skill h3 {
  font-size: 2rem;
}

.segment i {
  font-size: 2rem;
}

.resources-and-courses {
  background-color: #1e1e1e;
  padding: 2rem;
}

.courses {
  color: white;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  font-family: "Roboto", sans-serif;

}

.platform h2 {
  color: #ffa500;
  margin-top: 2rem;
}

.courses h6 {
  color: #27c3df;

}

.courses h1,
h2,
h3 {
  display: inline-block;
  margin: 0.2rem 0 0.2rem 0;
}
.courses h2 {
  text-decoration: underline;
}

.courses i {
  color: #ffa500;
  margin: .5rem;
}

.courses h3 {
  font-size: 1rem;
}

.courses h6 {
  margin: 0.2rem;
  font-size: 0.85rem;
  font-weight: 50;
  letter-spacing: 0.1rem;
}


/* About */

.about {
  display: flex;
  width: 100%;
  margin: auto;
  padding: 3rem;
  justify-content: space-around;
}

.pic {
  margin: 0 auto;
  width: 25rem;
  border-radius: 5px;
  box-shadow: 1rem black;
}

.bio {
  padding: 2rem;
  width: 70%;
  border-radius: 5px;
  padding: 1rem;
  background-color: #1e1e1e;
}

.bio-heading {
  color: white;
  font-size: 3rem;
}

.bio-links {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin: auto;
}

.bio-links button {
  background-color: #6A9955;
  border: none;
  border-radius: 50px;
  padding: 1rem;
  width: 15rem;
  box-shadow: 0 0 1rem 1rem #252526;
  font-size: 1.1rem;
  font-weight: bold;
  font-family: 'Roboto', sans-serif
}

.bio-links button:hover {
  background-color: #4caf50;
  box-shadow: 0 0rem 0rem 0rem #252526;
  transform: translateY(5%);
}

.bio-links button i {
  margin-right: .5rem
}

span.let {
  color: #055099
}

span.developer {
  color: #27C3DF;
}

span.name-property {
  margin-left: 20px;
  color: #6A9955;
}

span.name-value {
  color: #D9911A
}

.bio p {
  margin: 2rem auto 3rem;
  text-align: justify;
  color: white;
  line-height: 2.2rem;
}

/* Form */

.contact {
  width: 100%;
  padding: 5rem;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}

.contact-pic {
  width: 20%;
  margin: 1rem;
  height: auto;
}

.contact-form {
  margin: auto;
  width: 70%;
  padding: 2rem;
}

.contact-form form {
  display: flex;
  flex-direction: column;
}


.email h1 {
  margin-top: 1rem;
  color: #6a9955;
}

input[type="text"],
input[type="email"],
textarea {
  width: 100%;
  padding: 1rem;
  margin: 1rem auto;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  background-color: #cccccc;
}

textarea:focus,
input:focus {
  outline: none;
  background-color: #d4d4d4;
}

input[name="message"] {
  width: 100%;
  height: 20rem;
}

form button {
  background-color: #6A9955;
  border: none;
  border-radius: 50px;
  padding: 1rem;
  width: 15rem;
  box-shadow: 0 0 1rem 1rem #252526;
  font-size: 1.1rem;
  font-weight: bold;
  font-family: 'Roboto', sans-serif
}

form button:hover {
  background-color: #4caf50;
  box-shadow: 0 0rem 0rem 0rem #252526;
  transform: translateY(5%);
}


/* Demos */

.page-heading {
  display: flex;
  justify-content: center;
  font-size: 1.5rem;
}

.demos h1 {
  color: #4caf50;
}

.demos h2 {
  margin-bottom: 1rem;
  font-family: "M Plus 1 Code", sans-serif;
}

.demos li {
  margin: 1rem;
  font-family: 'Roboto', sans-serif;
  font-size: 1.1rem;
}

.content-container {
  display: flex;
  flex-direction: column; 
  margin-bottom: 5rem;
  width: auto;
}


.demo-description {
  color: white;
  border-radius: 5px;
  transition: transform 1s ease-in-out;
  padding: 2rem;
  background-color: #1e1e1e;

}

.demo-description button {
  margin: 1rem
}

.video-container {
  padding-bottom: 10px; 
}

.testimonials {
  font-size: 1.2rem;
}

.quote {
  font-style: italic;
}

.demo-description i {
  font-size: 1.5rem;
  margin-left: 1rem;
}

i.demo-link {
  color: #4caf50;

}

i.demo-link:hover {
  color: white;
}

@media (max-width: 900px){
  .contact {
    padding: 1rem;
    flex-direction: column;
  }
  .contact-form, .contact-pic {
    margin: auto;
    width: 100%;
  }
  .courses {
    width: 100%;
    padding:0;
    margin: auto;
    grid-template-columns: 1fr;
  }

  p {
    text-align: left;
  }
}

@media (max-width: 650px)  {

 
  header img {
    display: none;
  }

  header h1 {
    font-size: 2rem;
    text-align: left;
  }

  .nav {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    position: fixed;
    right: -100%;
    background-color: rgba(0, 0, 0, .9);;
    height: 100vh;
    width: 100vw;
    visibility: hidden;
    transition: right 0.3s ease, opacity 0.3s ease, visibility 0.3s ease; 
  }
  
  .nav.open {
    right: 0;
    opacity: 1;
    visibility: visible;
  }

  nav ul {
    flex-direction: column; 
    list-style: none;
  }

  nav li {
    text-align: center;
    font-size: 1rem;
    margin-bottom: 2rem;
  }

  nav a {
    text-decoration: underline;
  }

  nav a:hover {
    text-decoration: none;
  }

  nav a:active {
    text-decoration: none;
  }

  .hamburger {
    cursor: pointer;
    z-index: 2;
    display: block;
    position: absolute;
    top: 1rem;
    right: 1rem;
    background-color: transparent;
    border: none;
    margin-right: 1rem;
    font-size: 2rem;
    color: #b267e6;
  }  

  .about {
    width: 100%;
    margin: auto;
    padding: 1rem;
    flex-direction: column;
    justify-content: center;
  }

  .about h1 {
    font-size: 1.5rem;
  }

  .about img {
    width: 100%;
    margin: auto;
    height: auto;
  }

  .bio {
    margin: 1rem auto;
    width: 100%;
  }

  .bio-links {
    display: grid;
    gap: 2rem;
  }

  .skills-segment {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }

  .skill {
    font-size: 1rem;
    margin: 1rem
  }

  .skill h3 {
    font-size: 1rem;
  }

  .links {
    left: 2rem;
  }
  

}

@media (min-width: 768px) {
    .content-container {
      flex-direction: row; 
      align-items: center;
      justify-content: center;
    }
    

  .video-container {
    flex: 1; 
    padding: 5rem;
  }

  .text-container {
    flex: 1;
    margin: 5rem;
  }

  
}